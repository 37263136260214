<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('my_make_up_exams')"
                        :isColumns="true"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-' + key"
                                :name="'checkbox-' + key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
                </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('my_make_up_exams')"
                              :isColumns="true"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-' + key"
                                :name="'checkbox-' + key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable
                :title="$t('exams')"
                :isLoading="datatable.isLoading"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :total="datatable.total"
                :queryParams="datatable.queryParams"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable"
            >
            </datatable>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

import Datatable from "@/components/datatable/Datatable";


import MakeUpExamService from "@/services/MakeUpExamService";



export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        Datatable,
    },
    metaInfo() {
        return {
            title: this.$t("my_make_up_exams"),
        };
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: this.toUpperCase("student_program_id"),
                        field: "student_program_id",
                        hidden: true,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("course_code"),
                        field: "course_code",
                        hidden: true,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("course_name"),
                        field: this.getLocaleField("course_name"),
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("final_letter_grade"),
                        field: 'final_letter_grade',
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("letter_grade"),
                        field: "letter_grade",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("credit"),
                        field: "credit",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("ects_credit"),
                        field: "ects_credit",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("instructor"),
                        field: "instructor",
                        hidden: false,
                        sortable: false,
                    }
                ],
                rows: [],
                queryParams:{
                    page:1,
                    limit:20,
                    sort:'-id'
                },
                total: 0,
                showTable: false,
            },
            student:{},
        };
    },
    created() {
        this.getRows();
    },
    methods: {
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            MakeUpExamService.getStudent()
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
    },
};
</script>
